<template>
    <section>
        <modal-lateral ref="modalCarritoAlmacen" titulo="Añadir al Carrito de Abastecimiento">
            <div class="col px-0 alto">
                <div class="p-3 f-15 overflow-auto custom-scroll" style="max-height: calc(100vh - 220px);">
                    <div class="row mx-0 justify-center">
                        <card-producto-carrito
                        v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                        :producto="prod"
                        />
                    </div>
                </div>
                <div class="br-10 mx-5 p-1 row justify-content-between text-white d-middle cr-pointer" style="background:red" @click="confirmar">
                    <span class="bg-danger px-2 py-1 br-10">{{ productos.length }}</span>
                    Añadir:{{ convertMoneyTendero(total_pedido,$tienda.idm_moneda) }}
                </div>
            </div>
        </modal-lateral>
        <modalSiNo
        ref="modalAnadir"
        titulo="Añadir al Carrito"
        mensaje="¿Desea Añadir los Productos al Carrito de Abastecimiento?"
        icon="Alistar"
        @accion="agregarAcarritoTendero"
        />
        <modal-pedido-almacen ref="modalPedidoAlmacen" />
    </section>
</template>

<script>
import Almacen from "~/services/almacen/almacen"
import {mapGetters} from 'vuex'
export default {
    components:{
        cardProductoCarrito: () => import('./card-producto-carrito'),
        modalPedidoAlmacen: () => import('./modalRealizarPedidoAbast')
    },
    props:{
        carrito:{
            type:Boolean,
            default:false
        },
        pedido:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            productos:[],
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
        }),
        total_pedido(){
            let total = 0;

            for (const p of this.productos){
                if(p.promocion){
                    total += p.cantidad * p.data_promocion.promo_valor
                    continue
                }else{
                    total += p.cantidad * p.precio
                }

            }
            return total
        }
    },
    methods: {
        async toggle(){
            if(this.id_pedido === null){
                this.notificacion('Alerta','Por favor selecciona un pedido','warning')
                return
            }

            try {
                const {data} = await Almacen.get_productos(this.id_pedido)
                this.productos = data.productos
            } catch (e){
                this.error_catch(e)
            }


            this.$refs.modalCarritoAlmacen.toggle()
        },
        async agregarAcarritoTendero(res){
            if(!res){
                this.$refs.modalAnadir.toggle()
                this.$refs.modalCarritoAlmacen.toggle()
                return
            }
            try {
                let productos = []
                for (const p of this.productos){
                    productos.push({id_producto:p.id,cantidad:p.cantidad})
                }

                const {data} = await Almacen.agregar_lista_productos(productos)
                this.$refs.modalAnadir.toggle()
                this.notificacion('Éxito','Productos agregados al carrito de abastecimiento','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        confirmar(){

            if(this.carrito){
                this.$refs.modalAnadir.toggle()
            }
            if(this.pedido){
                let productos = []
                for (const p of this.productos){
                    productos.push({id_producto:p.id,cantidad:p.cantidad})
                }
                this.$refs.modalPedidoAlmacen.toggle(productos)
            }

            this.$refs.modalCarritoAlmacen.toggle()
        },
        remover_de_lista(id_producto){
            let idx = this.listado_productos.findIndex(p=>p.id === id_producto)
            this.listado_productos.splice(idx, 1);
        },

    }
}
</script>

<style lang="scss" scoped>
     .card-producto{
       // width: 365px;
        height: 89px;
        display: flex;
        align-items: center;
        margin:  8px;
        &__img{
            object-fit: contain;
        }
        &__figure{
            width: 89px;
            height: 89px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
        }
        &__detalles{
            text-align: start;
            padding: 5px;
            width: 250px;
        }
        &__titulo{
            margin: 0 !important;
            word-break: break-word;
        }
        &__presentacion{
            color: #707070;
        }
        &__descuento{
            border-radius: 22px;
            font-size: 11px;
            color: #fff;
            height: 17px;
            text-align: center;
            text-transform: uppercase;
            padding:  2px 10px 0;
            width: fit-content;
        }
        &__contador{
            width: 85px !important;
        }
        &__precio_descuento{
            color: #dee2e6;
            text-decoration: line-through;
        }
    }
</style>

